import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AffiliateReview, LocaleHelper } from '@sunny-cars/util-global';
import { EkomiLogoComponent } from '../ekomi-logo/ekomi-logo.component';

export interface Rating {
	review: AffiliateReview;
	name: string;
}

@Component({
	selector: 'ui-ratings',
	templateUrl: 'ratings.component.html',
	standalone: true,
	imports: [CommonModule, EkomiLogoComponent, DecimalPipe, TranslateModule],
})
export class RatingsComponent {
	@HostBinding('class') class = 'w-full';

	@Input({ required: true }) reviews: Rating[] = [];
	@Input() centerAligned?: boolean;
	@Input() hasHorizontalLayout?: boolean;
	@Input() isAnimatedOnMobile?: boolean;

	constructor(readonly localeHelper: LocaleHelper) {}
}
