const plugin = require('tailwindcss/plugin');
const { replaceTailwindUnit, remUnitRegex } = require('tailwind-unit-replace');
const iconData = require('./libs/util-global/src/assets/ui-icons.json');

/**
 * bg-[icon] setup
 * @deprecated
 */
const iconObject = {};
// Value is the hex value of the corresponding color
const iconColors = [
	{ key: 'DEFAULT', value: '000000' },
	{ key: 'white', value: 'FFFFFF' },
	{ key: 'secondary', value: '007A76' },
	{ key: 'selected', value: '007A76' },
	{ key: 'ui-disabled', value: '748A9561' },
	{ key: 'ui', value: '748A95' },
	{ key: 'ui-critical', value: '5A6B74' },
	{ key: 'text-secondary', value: '000000D9' },
	{ key: 'success', value: '008836' },
	{ key: 'warning', value: 'B56201' },
	{ key: 'error', value: 'AB0000' },
];

function registerIcon(key, value) {
	const icon = `url('data:image/svg+xml;charset=utf-8,${value
		.replace(/</g, '%3C')
		.replace(/>/g, '%3E')
		.replace(/#/g, '%23')
		.replace(/&/g, '%26')}')`;

	iconColors.forEach((color) => {
		if (color.key === 'DEFAULT') {
			iconObject[key] = icon;
		} else {
			iconObject[key + '-' + color.key] = icon.replace(
				/%23000000/g,
				'%23' + color.value,
			);
		}
	});
}

for (let iconKey in iconData.icons) {
	registerIcon(iconKey, iconData.icons[iconKey].svg);
}
for (let aliasKey in iconData['icon-aliases']) {
	registerIcon(
		aliasKey,
		iconData.icons[iconData['icon-aliases'][aliasKey]].svg,
	);
}

const config = {
	content: ['./apps/**/*.{html,ts}', './libs/**/*.{html,ts}'],
	theme: {
		extend: {
			aspectRatio: {
				'4/3': '4 / 3',
				'16/9': '16 / 9',
			},
			gridTemplateColumns: {
				collection: 'repeat(auto-fit, minmax(240px, 1fr))',
				'collection-desktop': 'repeat(auto-fit, minmax(300px, 1fr))',
				'cover-tablet':
					'minmax(1rem, 1fr) repeat(2, minmax(150px, 42rem)) minmax(1rem, 1fr)',
				'cover-desktop':
					'minmax(0.5rem, 1fr) repeat(2, minmax(320px, 40.75rem)) minmax(0.5rem, 1fr)',
				hero: 'repeat(24, 1fr)',
				'hero-xs': `minmax(0.75rem, calc(((100% - 60rem) / 2) - 0.75rem)) repeat(24, 1fr)
							minmax(0.75rem, calc(((100% - 60rem) / 2) - 0.75rem))`,
				'hero-sm': `minmax(1.5rem, calc(((100% - 60rem) / 2) - 0.75rem)) repeat(24, 1fr)
							minmax(1.5rem, calc(((100% - 60rem) / 2) - 0.75rem))`,
			},
			gridTemplateRows: {
				cover: '300px 1fr',
				hero: '2.25rem repeat(2, auto)',
			},
			gridColumnEnd: {
				'hero-background': 'span 26',
				'hero-full': 'span 24',
				'hero-2/3': 'span 15',
				'hero-1/3': 'span 9',
				full: 'span 12',
				'2/3': 'span 8',
				'1/2': 'span 6',
				'1/3': 'span 4',
			},
			gridColumnStart: {
				'hero-2/3': '17',
				'hero-1/3': '9',
				'2/3': '8',
				'1/2': '6',
				'1/3': '4',
			},
			height: {
				'fit-content': 'fit-content',
				'9/10': '90%',
				4.5: '1.125rem',
				5.5: '1.375rem',
				30: '7.5rem',
				'popover-header-bottom': 'calc(100vh - 3.5rem)',
				'sheet-like': 'calc(90% - var(--sheet-bottom-offset, 0px))',
				'sheet-like-xs': 'calc(90% - var(--sheet-bottom-offset-xs, 0px))',
				'maps-mobile-sheet': 'calc(100% - 58px)',
				'ratings-spacer': '38px',
			},
			width: {
				'rate-card-img': '106px',
				'rate-card-img-sm': '126px',
				'rate-card-img-lg': '112px',
				sheet: '560px',
				'filter-sheet': '348px',
				'hero-widget': '61.8%;',
				'hero-aside': '38.2%',
				'form-label': '36%;',
				'form-control': '64%',
				'map-info-card': '260px',
				4.5: '1.125rem',
				5.5: '1.375rem',
			},
			minWidth: {
				popover: '320px',
				'widget-usp': '256px',
				'map-info-card': '260px',
			},
			maxWidth: {
				card: '440px',
				hero: '60rem',
				'widget-usp': '320px',
				widget: '640px',
				'filter-tag': '260px',
				'1/2': '50%',
				'9/10': '90%',
				'2xs': '15rem',
				'4.5xl': '60rem',
				'7xl': '79rem',
				'7.5xl': '84rem',
				'optimized-reading': '47rem',
				'optimized-reading-heading': '55rem',
				'compare-1-rate': 'calc(2 * 420px)',
				'compare-2-rates': 'calc(3 * 420px)',
				'compare-3-rates': 'calc(4 * 420px)',
				'booking-car-selection-content': '1290px',
				'booking-content': '1168px',
			},
			maxHeight: {
				'3/4': '75%',
				'4/5': '80%',
				'9/10': '90%',
				'85vh': '85vh',
				'90vh': '90vh',
				sidebar: 'calc(100vh - 3rem)',
				'sheet-like': 'calc(90% - var(--sheet-bottom-offset, 0px))',
				'sheet-like-xs': 'calc(90% - var(--sheet-bottom-offset-xs, 0px))',
			},
			minHeight: {
				11: '2.75rem',
				28: '7rem',
				36: '9rem',
				input: '2.5rem',
				'mobile-input': '3rem',
				'popover-header': '30px',
				'car-selection-filter': '400px',
				hero: '35rem',
			},
			inset: {
				'sheet-like-offset': 'var(--sheet-bottom-offset, 0px)',
				'sheet-like-offset-xs': 'var(--sheet-bottom-offset-xs, 0px)',
			},
			margin: {
				0.75: '0.1875rem',
				0.5: '0.125rem',
				'-18': '-4.5rem',
				'screen-w-half': '50vw',
			},
			spacing: {
				8.25: '2.125rem',
				6.25: '1.625rem',
				8.5: '2.25rem',
				'3/4': '75%',
			},
			flexBasis: {
				112: '28rem',
			},
			backgroundSize: {
				icon: '16px',
				'icon-small': '12px',
				3: '0.75rem',
				2.5: '0.625rem',
				2: '0.5rem',
				full: '100% 100%',
			},
			borderWidth: {
				1: '1px',
				6: '6px',
				10: '10px',
			},
			fontSize: {
				'2xs': '0.625rem',
				0: '0',
				lg: ['1.125rem', '1.375'],
				xl: ['1.25rem', '1.125'],
			},
			lineHeight: {
				4.5: '1.125rem',
			},
			letterSpacing: {
				'custom-promotion': '-1px',
			},
			backdropBlur: {
				xs: '2px',
			},
			backgroundImage: {
				...iconObject,
				'page-gradient':
					'radial-gradient(ellipse at center, var(--color-frame-dominant-300) 0%, var(--color-frame-dominant-600) 100%);',
				'hero-gradient':
					'radial-gradient(farthest-side at 100% 100%, var(--color-frame-dominant-300) 0%, var(--color-frame-dominant-600) 100%)',
				'hero-gradient-desktop':
					'radial-gradient(farthest-side at 18% 100%, var(--color-frame-dominant-300) 0%, var(--color-frame-dominant-300) 35%, var(--color-frame-dominant-600) 100%)',
				'hero-image-overlay-gradient':
					'linear-gradient( to top, #fff 0%, rgba(255, 255, 255, 0) 38.2%);',
				'image-underlay-gradient':
					'linear-gradient(rgba(0,0,0,0.7) 0, #000 100%)',
				'discount-gradient': `linear-gradient(
					to top left,
					rgb(0 0 0 / 0%) 0%,
					rgb(0 0 0 / 0%) calc(50% - 1.4px),
					currentcolor 50%,
					rgb(0 0 0 / 0%) calc(50% + 1.4px),
					rgb(0 0 0 / 0%) 100%
				)`,
				'filter-map': "url('/assets/images/filter-maps-image.png')",
				'hide-rotated-top':
					'linear-gradient(-225deg, transparent 50%, currentColor 50%)',
			},
			backgroundPosition: {
				'left-center': 'left center',
				'left-3-center': 'left 0.75rem center',
				'left-5-center': 'left 1.25rem center',
				'left-5-top': 'left 1.25rem top 2.25rem',
				'right-3-center': 'right 0.75rem center',
				'right-4-center': 'right 1rem center',
				'right-9-center': 'right 2.25rem center',
			},
			backgroundColor: {
				'ui-root-half-transparent': 'var(--color-ui-root-half-transparent)',
				'ui-root-transparent': 'var(--color-ui-root-transparent)',
				'black-transparent': 'var(--color-black-transparent)',
				'header-button-transparent': 'rgba(255, 255, 255, 0.2)',
				'header-footer': {
					low: 'var(--color-header-footer-background-low)',
					moderate: 'var(--color-header-footer-background-moderate)',
					high: 'var(--color-header-footer-background-high)',
					DEFAULT: 'var(--color-header-footer-background)',
					important: 'var(--color-header-footer-background-important)',
					critical: 'var(--color-header-footer-background-critical)',
				},
				widget: 'var(--color-widget-background)',
				'widget-submit': 'var(--color-widget-submit-background)',
				'header-navigation': 'var(--color-header-navigation-background)',
			},
			padding: {
				1.75: '7px',
				5.5: '22px',
				'aspect-ratio-16/9': '56.25%',
				'aspect-ratio-1/1': '100%',
				'1/10': '10%',
				'1/6': '16.666667%',
				'screen-w-half': '50vw',
			},
			opacity: {
				disabled: '0.38',
				'read-only': '0.75',
			},
			cursor: {
				tooltip: 'pointer',
			},
			animation: {
				'drive-away': 'drive-away-keyframes 0.8s ease-out',
				'rate-details-header-highlight':
					'rate-details-header-highlight-keyframes 800ms ease-in 650ms',
				'skeleton-shine': '2s ease-in-out infinite skeleton-shine-keyframes',
				'horizontal-ratings':
					'10s infinite horizontal-ratings-keyframes ease-in-out',
			},
			keyframes: {
				'drive-away-keyframes': {
					'0%': {
						top: '50%',
						transform: 'translateY(-50%)',
						left: '16px',
					},
					'30%': {
						top: '50%',
						transform: 'translateY(-50%)',
						left: '-40px',
						opacity: 0,
					},
					'31%': {
						top: '-22px',
						left: '-40px',
					},
					'32%': {
						top: '-22px',
						left: '150%',
					},
					'33%': {
						top: '50%',
						transform: 'translateY(-50%)',
						left: '150%',
						opacity: 0,
					},
					'100%': {
						top: '50%',
						transform: 'translateY(-50%)',
						left: '16px',
						opacity: 1,
					},
				},
				'rate-details-header-highlight-keyframes': {
					from: {
						'background-color': 'var(--color-ui-moderate)',
					},
					to: {
						'background-color': 'var(--color-ui-low)',
					},
				},
				'skeleton-shine-keyframes': {
					'0%': {
						opacity: 1,
					},
					'50%': {
						opacity: 0.5,
					},
					'100%': {
						opacity: 1,
					},
				},
				'horizontal-ratings-keyframes': {
					'0%': {
						opacity: 0,
						transform: 'translateX(100%)',
					},
					'2.5%': {
						opacity: 0,
					},
					'10%': {
						opacity: 1,
						transform: 'translateX(0)',
						left: '12px',
					},
					'30%': {
						opacity: 1,
						transform: 'translateX(0)',
						left: '12px',
					},
					'40%': {
						opacity: 0,
						transform: 'translateX(-100%)',
					},
					'100%': {
						opacity: 0,
						transform: 'translateX(-100%)',
					},
				},
			},
			/**
			 * Check docs at https://tailwindcss.com/docs/typography-plugin for more options
			 * This is the default sunnycars styling
			 */
			typography: (theme) => ({
				DEFAULT: {
					css: {
						'--tw-prose-bullets': 'var(--color-text-primary)',
						color: 'var(--color-text-primary)',
						fontSize: theme('fontSize.base'),
						fontWeight: theme('fontWeight.normal'),
						lineHeight: theme('fontSize.lg'),
						fontFamily: theme('fontFamily.default').join(),
						marginTop: 0,
						marginBottom: 0,
						maxWidth: theme('maxWidth.optimized-reading'),
						'.h1': {
							fontFamily: theme('fontFamily.heading').join(),
							color: 'var(--color-heading)',
							fontSize: theme('fontSize.4xl'),
							fontWeight: theme('fontWeight.normal'),
							lineHeight: 1.125,
							marginTop: 0,
							marginBottom: '1.5rem',
						},
						'.h2': {
							fontFamily: theme('fontFamily.heading').join(),
							color: 'var(--color-subheading)',
							fontSize: theme('fontSize.xl'),
							fontWeight: theme('fontWeight.semibold'),
							lineHeight: 1.125,
							marginTop: '0.75rem',
							marginBottom: '0.75rem',
						},
						h1: {
							fontFamily: theme('fontFamily.heading').join(),
							color: 'var(--color-heading)',
							fontSize: theme('fontSize.4xl'),
							fontWeight: theme('fontWeight.normal'),
							lineHeight: 1.125,
							marginTop: 0,
							marginBottom: '1.5rem',
						},
						h2: {
							fontFamily: theme('fontFamily.heading').join(),
							color: 'var(--color-subheading)',
							fontSize: theme('fontSize.xl'),
							fontWeight: theme('fontWeight.semibold'),
							lineHeight: 1.125,
							marginTop: '0.75rem',
							marginBottom: '0.75rem',
						},
						h3: {
							fontSize: theme('fontSize.lg'),
							fontWeight: theme('fontWeight.semibold'),
							lineHeight: 1.125,
							marginTop: '0.5rem',
							marginBottom: '0.5rem',
						},
						h4: {
							fontSize: theme('fontSize.base'),
							fontWeight: theme('fontWeight.semibold'),
							lineHeight: 1.125,
							marginTop: '0.5rem',
							marginBottom: '0.5rem',
						},
						h5: {
							fontSize: theme('fontSize.sm'),
							fontWeight: theme('fontWeight.semibold'),
							lineHeight: 1.125,
							marginTop: '0.5rem',
							marginBottom: '0.5rem',
						},
						h6: {
							fontSize: theme('fontSize.xs'),
							fontWeight: theme('fontWeight.semibold'),
							lineHeight: 1.125,
							marginTop: '0.5rem',
							marginBottom: '0.5rem',
						},
						a: {
							color: 'var(--color-secondary)',
						},
					},
				},
				lg: {
					css: {
						fontSize: theme('fontSize.lg'),
						lineHeight: theme('lineHeight.tight'),
						maxWidth: theme('maxWidth.optimized-reading'),
						'.h1': {
							fontFamily: theme('fontFamily.heading').join(),
							fontSize: theme('fontSize.5xl'),
							fontWeight: theme('fontWeight.normal'),
							lineHeight: 1.125,
							marginTop: 0,
							marginBottom: '1.5rem',
						},
						'.h2': {
							fontFamily: theme('fontFamily.heading').join(),
							fontSize: theme('fontSize.2xl'),
							fontWeight: theme('fontWeight.semibold'),
							lineHeight: 1.125,
							marginTop: '0.75rem',
							marginBottom: '0.75rem',
						},
						h1: {
							fontFamily: theme('fontFamily.heading').join(),
							fontSize: theme('fontSize.5xl'),
							fontWeight: theme('fontWeight.normal'),
							lineHeight: 1.125,
							marginTop: 0,
							marginBottom: '1.5rem',
						},
						h2: {
							fontFamily: theme('fontFamily.heading').join(),
							fontSize: theme('fontSize.2xl'),
							fontWeight: theme('fontWeight.semibold'),
							lineHeight: 1.125,
							marginTop: '0.75rem',
							marginBottom: '0.75rem',
						},
						h3: {
							fontSize: theme('fontSize.xl'),
							fontWeight: theme('fontWeight.semibold'),
							lineHeight: 1.125,
							marginTop: '0.5rem',
							marginBottom: '0.5rem',
						},
						h4: {
							fontSize: theme('fontSize.lg'),
							fontWeight: theme('fontWeight.semibold'),
							lineHeight: 1.125,
							marginTop: '0.5rem',
							marginBottom: '0.5rem',
						},
						h5: {
							fontSize: theme('fontSize.base'),
							fontWeight: theme('fontWeight.semibold'),
							lineHeight: 1.125,
							marginTop: '0.5rem',
							marginBottom: '0.5rem',
						},
						h6: {
							fontSize: theme('fontSize.sm'),
							fontWeight: theme('fontWeight.semibold'),
							lineHeight: 1.125,
							marginTop: '0.5rem',
							marginBottom: '0.5rem',
						},
					},
				},
				'text-black-sm': {
					css: {
						fontSize: theme('fontSize.sm'),
						a: {
							color: 'var(--color-text)',
							textDecorationLine: 'underline',
						},
					},
				},
			}),
			userSelect: {
				none: 'none',
			},
		},
		screens: {
			'2xs': '350px',
			xs: '400px',
			sm: '640px',
			md: '770px',
			lg: '1040px',
			xl: '1280px',
			'2xl': '1536px',
			'hero-layout-desktop': '858px',
		},
		borderRadius: {
			DEFAULT: 'calc(var(--border-radius) * 0.5)',
			none: '0px',
			small: 'calc(var(--border-radius) * 0.25)',
			medium: 'calc(var(--border-radius) * 0.75)',
			large: 'var(--border-radius)',
			checkbox: '4px',
			input: 'var(--border-radius)',
			button: 'var(--border-radius)',
			'arrow-button': '8px',
			card: 'var(--border-radius)',
			'rate-card-box': 'calc(var(--border-radius) * 0.5)',
			popover: 'var(--border-radius)',
			'popover-2x': 'calc(var(--border-radius) * 1.5)',
			full: '99999px',
			'1/2': '50%',
		},
		colors: {
			transparent: 'transparent',
			currentcolor: 'var(--color-currentcolor)',
			white: 'var(--color-white)',
			'white/80': 'var(--color-white-transparent)',
			black: 'var(--color-black)',
			'black/40': 'rgba(0, 0, 0, 0.4)',
			facebook: 'var(--color-facebook)',
			twitter: 'var(--color-twitter)',
			linkedin: 'var(--color-linkedin)',
			youtube: 'var(--color-youtube)',
			pinterest: 'var(--color-pinterest)',
			instagram: 'var(--color-instagram)',
			vimeo: 'var(--color-vimeo)',
			anvr: 'var(--color-anvr)',
			pill: 'var(--color-pill)',
			'pill-blog': 'var(--color-pill-blog)',
			reviews: 'var(--color-reviews)',
			heading: 'var(--color-heading)',
			subheading: 'var(--color-subheading)',
			'widget-heading': 'var(--color-widget-heading)',
			'widget-subheading': 'var(--color-widget-subheading)',
			'widget-text': 'var(--color-widget-text)',
			text: {
				DEFAULT: 'var(--color-text-primary)',
				primary: 'var(--color-text-primary)',
				secondary: 'var(--color-text-secondary)',
				tertiary: 'var(--color-text-tertiary)',
				quaternary: 'var(--color-text-quaternary)',
			},
			primary: {
				low: 'var(--color-primary-low)',
				moderate: 'var(--color-primary-moderate)',
				high: 'var(--color-primary-high)',
				DEFAULT: 'var(--color-primary)',
				important: 'var(--color-primary-important)',
				critical: 'var(--color-primary-critical)',
			},
			secondary: {
				low: 'var(--color-secondary-low)',
				moderate: 'var(--color-secondary-moderate)',
				high: 'var(--color-secondary-high)',
				DEFAULT: 'var(--color-secondary)',
				important: 'var(--color-secondary-important)',
				critical: 'var(--color-secondary-critical)',
			},
			tertiary: {
				low: 'var(--color-tertiary-low)',
				moderate: 'var(--color-tertiary-moderate)',
				high: 'var(--color-tertiary-high)',
				DEFAULT: 'var(--color-tertiary)',
				important: 'var(--color-tertiary-important)',
				critical: 'var(--color-tertiary-critical)',
			},
			highlight: {
				low: 'var(--color-highlight-low)',
				moderate: 'var(--color-highlight-moderate)',
				high: 'var(--color-highlight-high)',
				DEFAULT: 'var(--color-highlight)',
				important: 'var(--color-highlight-important)',
				critical: 'var(--color-highlight-critical)',
			},
			ui: {
				root: 'var(--color-ui-root)',
				ground: 'var(--color-ui-ground)',
				low: 'var(--color-ui-low)',
				moderate: 'var(--color-ui-moderate)',
				high: 'var(--color-ui-high)',
				DEFAULT: 'var(--color-ui)',
				important: 'var(--color-ui-important)',
				critical: 'var(--color-ui-critical)',
			},
			success: {
				low: 'var(--color-success-low)',
				moderate: 'var(--color-success-moderate)',
				high: 'var(--color-success-high)',
				DEFAULT: 'var(--color-success)',
				important: 'var(--color-success-important)',
				critical: 'var(--color-success-critical)',
				active: 'var(--color-success-active)',
			},
			error: {
				low: 'var(--color-error-low)',
				moderate: 'var(--color-error-moderate)',
				high: 'var(--color-error-high)',
				DEFAULT: 'var(--color-error)',
				important: 'var(--color-error-important)',
				critical: 'var(--color-error-critical)',
			},
			info: {
				low: 'var(--color-info-low)',
				moderate: 'var(--color-info-moderate)',
				high: 'var(--color-info-high)',
				DEFAULT: 'var(--color-info)',
				important: 'var(--color-info-important)',
				critical: 'var(--color-info-critical)',
			},
			warning: {
				low: 'var(--color-warning-low)',
				moderate: 'var(--color-warning-moderate)',
				high: 'var(--color-warning-high)',
				DEFAULT: 'var(--color-warning)',
				important: 'var(--color-warning-important)',
				critical: 'var(--color-warning-critical)',
			},
			selected: {
				low: 'var(--color-selected-low)',
				moderate: 'var(--color-selected-moderate)',
				high: 'var(--color-selected-high)',
				DEFAULT: 'var(--color-selected)',
				important: 'var(--color-selected-important)',
				critical: 'var(--color-selected-critical)',
			},
			focus: {
				low: 'var(--color-focus-low)',
				moderate: 'var(--color-focus-moderate)',
				high: 'var(--color-focus-high)',
				DEFAULT: 'var(--color-focus)',
				important: 'var(--color-focus-important)',
				critical: 'var(--color-focus-critical)',
				gray: 'var(--color-focus-gray)',
			},
			'modal-backdrop': 'var(--color-modal-backdrop)',
			'frame-dominant': {
				50: 'var(--color-frame-dominant-50)',
				100: 'var(--color-frame-dominant-100)',
				200: 'var(--color-frame-dominant-200)',
				300: 'var(--color-frame-dominant-300)',
				400: 'var(--color-frame-dominant-400)',
				500: 'var(--color-frame-dominant-500)',
				600: 'var(--color-frame-dominant-600)',
				700: 'var(--color-frame-dominant-700)',
				800: 'var(--color-frame-dominant-800)',
				900: 'var(--color-frame-dominant-900)',
			},
		},
		fontFamily: {
			default: [
				'var(--font-family-text)',
				'ui-sans-serif',
				'system-ui',
				'-apple-system',
				'BlinkMacSystemFont',
				'Segoe UI',
				'Roboto',
				'Helvetica Neue',
				'Arial',
				'Noto Sans',
				'sans-serif',
				'Apple Color Emoji',
				'Segoe UI Emoji',
				'Segoe UI Symbol',
				'Noto Color Emoji',
			],
			heading: [
				'var(--font-family-heading)',
				'ui-sans-serif',
				'system-ui',
				'-apple-system',
				'BlinkMacSystemFont',
				'Segoe UI',
				'Roboto',
				'Helvetica Neue',
				'Arial',
				'Noto Sans',
				'sans-serif',
				'Apple Color Emoji',
				'Segoe UI Emoji',
				'Segoe UI Symbol',
				'Noto Color Emoji',
			],
			serif: [
				'Rooney',
				'ui-serif',
				'Georgia',
				'Cambria',
				'Times New Roman',
				'Times',
				'serif',
			],
			mono: [
				'ui-monospace',
				'SFMono-Regular',
				'Menlo',
				'Monaco',
				'Consolas',
				'Liberation Mono',
				'Courier New',
				'mono',
			],
		},
		fill: (theme) => ({
			...theme('colors'),
		}),
		boxShadow: {
			'reservation-card': '0 0 6px 3px rgba(0, 0, 0, 0.2)',
			checkbox: '0 0 5px var(--color-selected)',
			'input-focus': '0px 0 5px var(--color-focus)',
			'input-error': '-1px 0 5px var(--color-error-high)',
			'cluster-marker': '1px 1px 2px 1px rgba(0, 0, 0, 0.3)',
			stepperInput:
				'inset 0 0 0 2px var(--color-focus-important), 0 0 5px var(--color-focus-important)',
			stepperButton: 'inset 0 0 0 2px var(--color-focus-important)',
			toggleSwitch: '0 0 3px 2px var(--color-focus-important)',
			sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
			'input-autofill': '0 0 0px 1000px var(--color-highlight-low) inset',
			DEFAULT:
				'0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
			md: '0 1px 6px rgba(0, 0, 0, 0.2)',
			lg: '0 6px 16px rgba(0, 0, 0, 0.25)',
			xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
			'2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
			'3xl': '0 35px 60px -15px rgba(0, 0, 0, 0.3)',
			inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
			none: 'none',
			'nav-menu-inner': 'inset 0 1px 0 0 rgb(255 255 255 / 15%)',
		},
	},
	plugins: [
		require('@tailwindcss/typography'),
		/* Plugin to add icons as mask-image */
		plugin(({ addUtilities }) => {
			const iconPluginObject = {};

			function registerPluginIcon(key, value) {
				const icon = `url('data:image/svg+xml;charset=utf-8,${value
					.replace('<', '%3C')
					.replace('>', '%3E')
					.replace('#', '%23')
					.replace('&', '%26')}')`;

				iconPluginObject[`.${key}`] = icon;
			}

			for (let iconKey in iconData.icons) {
				registerPluginIcon(iconKey, iconData.icons[iconKey].svg);
			}
			for (let aliasKey in iconData['icon-aliases']) {
				registerPluginIcon(
					aliasKey,
					iconData.icons[iconData['icon-aliases'][aliasKey]].svg,
				);
			}

			const pluginEntries = {};
			Object.keys(iconPluginObject).forEach((key) => {
				pluginEntries[key] = {
					'mask-image': iconPluginObject[key],
					'mask-repeat': 'no-repeat',
					'mask-position': 'center',
				};
			});
			pluginEntries['.icon-size-regular'] = {
				'mask-size': '16px',
			};
			pluginEntries['.icon-size-small'] = {
				'mask-size': '12px',
			};
			pluginEntries['.icon-size-tiny'] = {
				'mask-size': '10px',
			};
			addUtilities(pluginEntries);
		}),
	],
};

function replaceRem(value) {
	return value.replace(remUnitRegex, (match, remDigit) => {
		const amount = parseFloat(remDigit);
		if (isNaN(amount)) {
			return match;
		}
		return `calc(${amount} * var(--custom-rem, 16px))`;
	});
}

module.exports = replaceTailwindUnit({
	replacer: replaceRem,
})(config);
