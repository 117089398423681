import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnChanges,
	Output,
	PLATFORM_ID,
	SimpleChanges,
	TemplateRef,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
	createPopper,
	Instance as PopperInstance,
	Placement,
} from '@popperjs/core';
import { PageScrollHelper } from '@sunny-cars/util-global/lib/helpers/page-scroll/page-scroll.helper';
import { first, Observable } from 'rxjs';
import { BaseControlComponent } from '../base.control';
import { IconComponent } from '../icon/icon.component';
import { SheetHeaderComponent } from '../sheet-header/sheet-header.component';

export interface PickerEntry {
	modifiedLabel?: string;
	label$?: Observable<string>;
	label: string;
	value: string | number;
	[key: string]: unknown;
}

@Component({
	selector: 'ui-picker',
	templateUrl: './picker.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: PickerComponent,
		},
	],
	standalone: true,
	imports: [CommonModule, IconComponent, SheetHeaderComponent],
})
export class PickerComponent
	extends BaseControlComponent
	implements AfterViewInit, OnChanges
{
	@Input() title = '';
	/* Template used for displaying skeleton entries */
	@Input() skeletonTemplate: TemplateRef<{ index: number }> | null = null;
	/* Template used for displaying entries */
	@Input() entryTemplate: TemplateRef<{
		entry: PickerEntry;
		isSelected: boolean;
		first: boolean;
	}> | null = null;
	/* Template used for displaying empty results */
	@Input() emptyTemplate: TemplateRef<void> | null = null;
	@Input() entries: PickerEntry[] = [];
	@Input() isLoading = false;
	@Input() isLoadingMore = false;
	@Input() hasStandardSuffixIcon = false;
	/* Initially selected value */
	@Input()
	set selected(value: string | number) {
		const selectedEntry = this.entries.find(
			(entry: PickerEntry) => `${entry.value}` === `${value}`,
		);
		if (selectedEntry) {
			this.handleSelect(selectedEntry);
		}
	}
	@Input() wrapperStyleModifiers = '';
	@Input() styleModifiers = '';
	@Input() resultsStyleModifiers = '';
	@Input() mainButtonTextSizeClass: 'text-lg' | 'text-base' | 'text-sm' =
		'text-lg';
	@Input() position: Placement = 'bottom';
	@Input() ignoreTrigger = false;
	@Input() isOpen = false;

	@Output() searchChange: EventEmitter<string> = new EventEmitter();
	@Output() selectedEntryChange: EventEmitter<PickerEntry> = new EventEmitter();
	@Output() value: EventEmitter<string | number> = new EventEmitter();
	@Output() clear: EventEmitter<void> = new EventEmitter();
	@Output() scrolledBottom: EventEmitter<void> = new EventEmitter();

	@ViewChild('trigger') triggerElement: ElementRef | undefined;
	@ViewChild('results') resultsElement: ElementRef | undefined;

	instance: PopperInstance | undefined;
	selectedEntry: PickerEntry | undefined;
	hasEntries = false;
	isInputReadonly = false;
	/**
	 * Label of the selected entry
	 */
	selectedValue = '';
	/**
	 * Whether to prevent closing on click outside
	 */
	private preventClose = false;

	constructor(
		private readonly pageScrollHelper: PageScrollHelper,
		@Inject(PLATFORM_ID) public platformId: string,
	) {
		super();
	}

	/**
	 * Handle click on element
	 */
	@HostListener('click')
	clickInside() {
		this.preventClose = true;
	}

	/**
	 * Handle click outside of element
	 */
	@HostListener('document:click')
	clickOutside() {
		if (!this.preventClose && this.isOpen) {
			this.close();
		}
		this.preventClose = false;
	}

	ngAfterViewInit(): void {
		/* istanbul ignore next */
		this.instance = createPopper(
			this.triggerElement?.nativeElement,
			this.resultsElement?.nativeElement,
			{
				placement: this.ignoreTrigger ? 'right' : this.position,
				modifiers: [
					{
						name: 'preventOverflow',
						options: {
							padding: 8,
						},
					},
					...(this.ignoreTrigger
						? [
								{
									name: 'offset',
									options: {
										/**
										 * Add custom offset to fall over trigger
										 * @returns {number[]}
										 */
										offset: () => [
											0,
											-this.triggerElement?.nativeElement.offsetWidth,
										],
									},
								},
							]
						: []),
				],
			},
		);

		if (isPlatformBrowser(this.platformId)) {
			// Detect and emit when bottom of list is reached
			const options: IntersectionObserverInit = {
				root: this.resultsElement?.nativeElement,
				threshold: 0,
			};
			/* istanbul ignore next */
			const observer = new IntersectionObserver(
				(event: IntersectionObserverEntry[]) => {
					if (event[0].isIntersecting) {
						this.scrolledBottom.emit();
					}
				},
				options,
			);
			observer.observe(
				this.resultsElement?.nativeElement.querySelector(
					'#scrollEnd',
				) as HTMLElement,
			);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['entries']) {
			this.hasEntries = changes['entries'].currentValue.length > 1;
			this.isInputReadonly =
				changes['entries'].currentValue.length === 1 || this.isReadonly;

			const selectedEntry = (
				changes['entries'].currentValue as PickerEntry[]
			).find((entry) => entry.value === this.selectedEntry?.value);
			if (selectedEntry && selectedEntry.label !== this.selectedEntry?.label) {
				this.handleSelect(selectedEntry, true);
			}
		}
	}

	highlightPrevious(event: Event): void {
		event.preventDefault();
		const selectedIndex = this.entries.findIndex(
			(entry: PickerEntry) =>
				`${entry.value}` === `${this.selectedEntry?.value}`,
		);
		if (selectedIndex === 0 || selectedIndex === -1) {
			this.selectedEntry = this.entries[0];
			this.scrollToHighlighted(true);
			return;
		}
		this.selectedEntry = this.entries[selectedIndex - 1];
		this.scrollToHighlighted(true);
	}

	highlightNext(event: Event): void {
		event.preventDefault();
		const selectedIndex = this.entries.findIndex(
			(entry: PickerEntry) =>
				`${entry.value}` === `${this.selectedEntry?.value}`,
		);
		if (selectedIndex === this.entries.length - 1) {
			this.selectedEntry = this.entries[0];
			this.scrollToHighlighted(true);
			return;
		}
		this.selectedEntry = this.entries[selectedIndex + 1];
		this.scrollToHighlighted(true);
	}

	selectHighlighted(event: Event): void {
		if (this.isOpen) {
			event.preventDefault();
		}
		if (this.selectedEntry) {
			this.handleSelect(this.selectedEntry);
		}
	}

	scrollToHighlighted(isSmooth = false): void {
		if (isPlatformBrowser(this.platformId)) {
			// @NOTE: Wait until selected has been updated
			setTimeout(() => {
				if (this.resultsElement?.nativeElement) {
					const elem =
						this.resultsElement.nativeElement.querySelector('.selected');
					if (elem) {
						this.resultsElement.nativeElement.scroll({
							left: 0,
							top:
								elem.offsetTop +
								elem.clientHeight / 2 -
								this.resultsElement.nativeElement.clientHeight / 2,
							behavior: isSmooth ? 'smooth' : 'auto',
						});
					}
				}
			});
		}
	}

	close(): void {
		this.isOpen = false;
		this.disableBackgroundScroll(false);
	}

	open(): void {
		this.isOpen = true;
		this.disableBackgroundScroll(true);
		setTimeout(() => {
			this.instance?.update();
		});
		this.scrollToHighlighted();
	}

	toggle(): void {
		if (this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	}

	handleSelect(entry: PickerEntry, omitTouched = false): void {
		this.selectedEntry = entry;
		if (entry.label$) {
			this.addSubscription(
				entry.label$
					.pipe(first())
					.subscribe((label) => (this.selectedValue = label)),
			);
		} else {
			this.selectedValue = entry.label;
		}
		this.selectedEntryChange.emit(entry);
		this.value.emit(entry.value);
		this.onChange(entry.value);
		if (!omitTouched) {
			this.onTouched();
		}
		this.close();
	}

	/**
	 * Handle value set by form
	 */
	override writeValue(value: string | number): void {
		this.entries.forEach((entry: PickerEntry) => {
			if (`${entry.value}` === `${value}`) {
				this.handleSelect(entry, true);
			}
		});
	}

	private disableBackgroundScroll(state: boolean): void {
		this.pageScrollHelper.toggleBackgroundScroll(state);
	}
}
