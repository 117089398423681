import { MetaDefinition } from '@angular/platform-browser';
import { ApplicationUrlEnvironment } from '../../interfaces/environment/environment.interface';
import { GtmConfig } from '../../interfaces/google-tag-manager/gtm-config.interface';
import { SourceType } from '../../interfaces/source/source.interface';
import { AffiliatedWith } from '../../types/affiliate/affiliated-with.type';

export interface Environment {
	// Environment config
	environment: 'mock' | 'uat1' | 'uat2' | 'prod';
	version: string;
	withCredentials: boolean;
	production: boolean;
	domain: {
		default: SupportedDomains;
		active: SupportedDomains;
		config: { [key: string]: Domain };
	};
	// Feature toggles
	legacyRedirectEnabled?: boolean;
	affiliateScriptsEnabled?: boolean;
	canFetchAccountDiscountCodes?: boolean;
	canFetchAccountLatestSearches?: boolean;
}

export enum SupportedDomains {
	NL = 'NL',
	DE = 'DE',
	BE = 'BE',
	AT = 'AT',
	CH = 'CH',
	FR = 'FR',
}

export interface Domain {
	// Feature toggles
	hasCarSelectionAllInclusiveBulletPoints?: boolean;
	// Domain config
	appBaseUrl: string;
	language: {
		default: string;
		supported: string[];
	};
	affiliateKey: number;
	affiliatedWith?: AffiliatedWith[];
	countryCode: string;
	source: SourceType;
	metaData?: MetaDefinition[];
	currency: string;
	isB2B?: boolean;
	shouldOptInConditions: boolean;
	shouldShowExitLayer: boolean;
	cookieDuration: number;
	applicationUrls: ApplicationUrlEnvironment;
	api: {
		bff: {
			baseUrl: string;
		};
		cms: {
			baseUrl: string;
			settings: {
				siteDomain: string;
				acceptLanguage: string;
			};
		};
	};
	apm?: EnvironmentAPMConfig;
	gtm?: {
		config: GtmConfig;
		affiliateConfig?: GtmConfig;
	};
	usercentrics?: {
		apiKey: string;
		dataProtectorEnabled?: boolean;
	};
	googleMaps?: {
		apiKey: string;
	};
	recaptcha?: {
		v2: {
			apiKey: string;
		};
		v3: {
			apiKey: string;
		};
	};
	expertrec?: {
		apiKey: string;
	};
	convert?: {
		apiKey: string;
	};
}

export interface EnvironmentAPMConfig {
	url: string;
	environment: string;
	origins: string[];
	labels: APMLabels;
}

export interface APMLabels {
	[key: string]: APMLabelValue;
}

export type APMLabelValue = string | number | boolean;
