<ng-template #reviewPlatformName let-name="name">
	@switch (name) {
		@case ('google') {
			{{ 'components.reviews.partner-name-google' | translate }}
		}
		@case ('ekomi') {
			{{ 'components.reviews.partner-name-ekomi' | translate }}
		}
		@case ('trustpilot') {
			{{ 'components.reviews.partner-name-trustpilot' | translate }}
		}
		@default {
			{{ name[0].toUpperCase() + name.slice(1) }}
		}
	}
</ng-template>

@if (!hasHorizontalLayout) {
	@if (reviews.length) {
		<aside class="mt-6 2xs:px-3 xs:px-6 sm:px-9">
			<div
				class="mx-auto flex max-w-hero flex-wrap gap-x-6 gap-y-4"
				[ngClass]="{ 'justify-center': centerAligned }"
			>
				<ng-template #reviewContents let-review="review">
					@if (['trustpilot', 'ekomi', 'google'].includes(review.name)) {
						<span class="h-8 w-8">
							@switch (review.name) {
								@case ('trustpilot') {
									<div
										class="h-8 w-8 bg-icon-social-official-trustpilot-colored"
									></div>
								}
								@case ('google') {
									<div
										class="h-8 w-8 bg-icon-social-circle-solid-google-reviews-colored"
									></div>
								}
								@case ('ekomi') {
									<ui-ekomi-logo
										class="h-8 w-8"
										[score]="review.review.value"
									></ui-ekomi-logo>
								}
							}
						</span>
					}
					<span class="ml-1 flex items-end gap-0.5 font-bold">
						<span class="text-3xl leading-none text-reviews">{{
							review.review.value
						}}</span>
					</span>
					<span class="mx-2 block h-full border-r border-r-ui-moderate"></span>
					<span class="flex flex-col gap-0.5 font-bold leading-none">
						<ng-container
							*ngTemplateOutlet="
								reviewPlatformName;
								context: {
									name: review.name
								}
							"
						></ng-container>
						<span class="text-sm leading-none opacity-60"
							>{{
								review.review.amount | number: '2.0-0' : localeHelper.locale
							}}
							{{ 'components.reviews.reviews' | translate }}</span
						>
					</span>
				</ng-template>
				@for (review of reviews; track $index) {
					@if (review.review.profileURL) {
						<a
							class="flex items-center rounded-full bg-ui-low px-4 py-2"
							[href]="review.review.profileURL"
							target="_blank"
							rel="noopener"
						>
							<ng-container
								*ngTemplateOutlet="
									reviewContents;
									context: {
										review: review
									}
								"
							></ng-container>
						</a>
					} @else {
						<div class="flex items-center rounded-full bg-ui-low px-4 py-2">
							<ng-container
								*ngTemplateOutlet="
									reviewContents;
									context: {
										review: review
									}
								"
							></ng-container>
						</div>
					}
				}
			</div>
		</aside>
	}
} @else {
	@if (reviews.length) {
		<ng-template #reviewContents let-platform="platform">
			<div class="flex items-center justify-center gap-1 sm:px-2">
				<div class="flex items-center gap-1.5">
					@switch (platform.name) {
						@case ('trustpilot') {
							<div
								class="size-5 bg-icon-social-official-trustpilot-colored lg:size-6"
							></div>
						}
						@case ('google') {
							<div
								class="size-5 bg-icon-social-circle-solid-google-reviews-colored lg:size-6"
							></div>
						}
						@case ('ekomi') {
							<ui-ekomi-logo
								class="size-5 lg:size-6"
								[score]="platform.review.value"
							></ui-ekomi-logo>
						}
					}
					<span class="text-xl font-bold text-success-high">
						{{ platform.review.value }}
					</span>
				</div>
				<div
					class="mx-1 hidden h-ratings-spacer w-px bg-ui-moderate lg:inline-block"
				></div>
				<span class="text-base font-bold">
					<ng-container
						*ngTemplateOutlet="
							reviewPlatformName;
							context: {
								name: platform.name
							}
						"
					></ng-container>
				</span>
				<span class="mt-0.5 text-sm text-text-quaternary"
					>{{ platform.review.amount }}
					{{ 'components.reviews.reviews' | translate }}
				</span>
			</div>
		</ng-template>
		<div
			class="relative h-6 w-full overflow-hidden whitespace-nowrap lg:flex lg:h-ratings-spacer"
		>
			@for (review of reviews; track $index) {
				@if (['trustpilot', 'ekomi', 'google'].includes(review.name || '')) {
					@if (review.review.profileURL) {
						<a
							[ngClass]="{
								'delay-one ': $index === 0,
								'delay-two': $index === 1,
								'delay-three': $index === 2,
								'animate-horizontal-ratings': isAnimatedOnMobile
							}"
							class="absolute w-full translate-x-full lg:relative lg:block lg:basis-full lg:translate-x-0 lg:animate-none"
							[href]="review.review.profileURL"
							target="_blank"
							rel="noopener"
						>
							<ng-container
								*ngTemplateOutlet="
									reviewContents;
									context: {
										platform: review
									}
								"
							></ng-container>
						</a>
					} @else {
						<div
							[ngClass]="{
								'delay-one ': $index === 0,
								'delay-two': $index === 1,
								'delay-three': $index === 2,
								'animate-horizontal-ratings': isAnimatedOnMobile
							}"
							class="absolute w-full translate-x-full lg:relative lg:block lg:basis-full lg:translate-x-0 lg:animate-none"
						>
							<ng-container
								*ngTemplateOutlet="
									reviewContents;
									context: {
										platform: review
									}
								"
							></ng-container>
						</div>
					}
				}
			}
		</div>
	}
}
